import * as React from "react"
import { Link } from "gatsby"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
// markup
const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Header containerClasses="container" />
      <Seo title="Page Not Found" pathname="/" />
      <main class="mt-0">
        <div class="hp-hero pt-4 pad-safe-lr">
          <div class="container">
            <div class="row my-0">
              <h1 class="display-1 fw-bold">Page not found</h1>
            </div>
          </div>
        </div>
        <div class="pad-safe-lr ">
          <div class="container my-3">
            <p class="lead">The URL wasn't found: 🤔</p>
            <p class="lead">Go <Link to='/'>Home</Link></p>
          </div>
        </div>
        <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
      </main>
      <Footer containerClasses="container" />
    </React.Fragment>
  )
}

export default NotFoundPage
